import React, { useRef, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useGetArticleByIdQuery } from '@/RTK/services/articleApi';
import { useGetPostByIdQuery } from '@/RTK/services/postsApi';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

import { truncateString } from '@/lib/utils';
import { Separator } from '@/components/ui/separator';

import { logo, star } from '../../../../../../public/images';
import ArticlePreview from '../components/ArticlePreview';
import { PostMediaCarousel } from '../components/postMediaCarousel';
import RepostedTweetCard from '../components/RepostedTweetCard';
import { getTimeAgo } from '../utils/helper';

export default function TweetCard({ tweet, ref, originalTweet, articleData }) {
  const postRef = useRef(null);
  const router = useRouter();
  const [showFullText, setShowFullText] = useState(false);

  function shouldCarouselOpen() {
    if (
      tweet?.content?.images.length > 1 ||
      (tweet?.content?.images.length === 1 && tweet?.content?.video)
    )
      return true;
    return false;
  }

  function renderTextContent() {
    if (!showFullText)
      return tweet?.content?.text.split('\n').slice(0, 6).join('\n');

    return tweet?.content?.text.replace(/\n/g, '\n &nbsp;');
  }

  return (
    <div
      className="relative flex flex-col justify-center items-center w-[1080px] h-[1350px] bg-purple-200 p-[20px]"
      ref={ref}
    >
      <div className="flex flex-col w-[95%] min-h-[50px] p-6 bg-white rounded-[12px] shadow-2xl pl-10">
        {/* Header Section */}
        <div className="flex justify-between items-center gap-4 pb-4  border-gray-100">
          <div className="flex items-center gap-3">
            {tweet?.author?.profileImage ? (
              <Image
                src={tweet?.author?.profileImage}
                alt={tweet?.author?.name || 'username'}
                width={68}
                height={68}
                unoptimized={true}
                className=" rounded-full"
              />
            ) : (
              <div className="w-16 h-16 rounded-full bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center">
                <span className="text-lg font-medium text-purple-600">
                  {tweet?.author?.name?.charAt(0) || 'A'}
                </span>
              </div>
            )}
            <div className="flex flex-col">
              <span className="text-[26px] font-semibold text-gray-900">
                {tweet?.author?.name || 'username'}
              </span>
            </div>
          </div>
          <Image
            src={
              'https://kitaba-files.s3.amazonaws.com/test/1742463169801-LA2_ePjw9E6rcg1Z7tIjN.png'
            }
            alt="logo"
            width={120}
            height={40}
            className="h-8 w-auto domtoimage-ignore"
            unoptimized={true}
          />
        </div>

        {/* Content Section */}
        <div className="pt-4">
          {/* Text Content */}
          <div className="text-[#363737]  text-[35px] font-medium tracking-[-0.4px] mr-4 text-justify break-normal break-words">
            <ReactMarkdown
              remarkPlugins={[remarkGfm, remarkBreaks]}
              components={{
                a: ({ node, ...props }) => (
                  <a
                    onClick={(e) => e.stopPropagation()}
                    {...props}
                    className="text-purple-600 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            >
              {renderTextContent()}
            </ReactMarkdown>
            {tweet?.content?.text.split('\n').length > 6 && !showFullText && (
              <span
                className="text-purple-600 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullText(true);
                }}
              >
                عرض المزيد...
              </span>
            )}
          </div>

          {/* Media Content */}
          <div className="relative w-full mt-4">
            {tweet?.content?.images.length === 1 && !tweet?.content?.video && (
              <div className=" overflow-hidden rounded-lg">
                <Image
                  src={tweet?.content?.images[0]}
                  alt={tweet?.author?.name || 'post image'}
                  width={0}
                  height={100}
                  className="w-full h-[200px] object-cover"
                  unoptimized={true}
                />
              </div>
            )}

            {shouldCarouselOpen() && (
              <div className=" overflow-hidden rounded-lg">
                <PostMediaCarousel
                  image={tweet?.content?.images}
                  video={tweet?.content?.video}
                />
              </div>
            )}
          </div>

          {!tweet?.isReshare && articleData && (
            <ArticlePreview
              article={articleData.article}
              openSimplePreview={() =>
                tweet.content.images.length !== 0 || tweet.content.video
              }
            />
          )}

          {tweet.isReshare && originalTweet && (
            <p className="text-purple-600 hover:underline text-[30px]">
              {url(originalTweet)}
            </p>
          )}
        </div>
        <div className="flex justify-center p-2">
          <p className="text-2xl font-medium text-gray-800 font-sans">
            {`kITABH.COM/@${tweet?.author?.username}`.toUpperCase()}
          </p>
        </div>
      </div>
    </div>
  );
}

function url(originalTweet) {
  let string = `kitabh.com/@${originalTweet?.post?.author?.username}`;
  return truncateString(string, 25);
}
