// if you make any changes to this component, please make sure to update SharedTweet component and Tweet component in the view post folder as well 😒
import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useGetArticleByIdQuery } from '@/RTK/services/articleApi';
import {
  useGetPostByIdQuery,
  useLikePostMutation,
  useUnlikePostMutation,
} from '@/RTK/services/postsApi';
import { useGetUserQuery } from '@/RTK/services/userApi';
import { getTime } from 'date-fns';
import domtoimage from 'dom-to-image';
import { toast } from 'react-hot-toast';
import { AiOutlineRetweet } from 'react-icons/ai';
import { BiComment } from 'react-icons/bi';
import { FaComment, FaDownload, FaHeart, FaRetweet } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { IoIosHeart, IoIosHeartEmpty } from 'react-icons/io';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

import { useFollow } from '../../../../../hooks/useFollow';
import TweetCard from '../download/TweetCard';
import { getTimeAgo, handleDownload } from '../utils/helper';
import ArticlePreview from './ArticlePreview';
import CommentModal from './CommentModal';
import { EditPostDialog } from './EditPostDialog';
import { PostMediaCarousel } from './postMediaCarousel';
import RepostedTweetCard from './RepostedTweetCard';
import { RepostOptionsDropdownMenu } from './RepostOptionsDropdown';
import SharedTweet from './SharedTweet';
import ShareModal from './ShareModal';
import { TweetSkeleton } from './skeletons/PostSkeletonLoader';
import TweetActionDropDownList from './TweetActionDropDownList';

export default function Tweet({ tweet, user }) {
  const [isLiked, setIsLiked] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isEditPostDialogOpen, setIsEditPostDialogOpen] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [downloadPhotoId, setDownloadPhotoId] = useState(null);

  const router = useRouter();
  const cardRef = useRef(null);

  const [numberOfRetweet, setNumberOfRetweet] = useState(
    tweet?.reshares?.length || 0
  );
  const [likePost] = useLikePostMutation();

  const [unlikePost] = useUnlikePostMutation();

  const { data: originalTweet, isLoading: originalTweetLoading } =
    useGetPostByIdQuery(tweet?.originalPost, {
      skip: !tweet?.isReshare,
    });

  const {
    data: articleData,
    isLoading: isLoadingArticle,
    error: articleError,
  } = useGetArticleByIdQuery(tweet?.content?.articleId, {
    skip: !tweet?.content?.articleId,
  });

  function shouldCarouselOpen() {
    if (
      tweet?.content?.images.length > 1 ||
      (tweet?.content?.images.length === 1 && tweet?.content?.video)
    )
      return true;
    return false;
  }

  function toggleLike() {
    if (!user) {
      toast.error('يجب عليك تسجيل الدخول اولاً');
      return;
    }
    if (isLiked) {
      unlikePost({ postId: tweet._id, userId: user.user._id });
    } else {
      likePost({ postId: tweet._id, userId: user.user._id });
    }
    setIsLiked(!isLiked);
  }

  const {
    isFollowing,
    handleFollow,
    canFollow,
    isLoading: followLoading,
  } = useFollow(tweet?.author?._id);

  function renderTextContent() {
    if (!showFullText)
      return tweet?.content?.text
        .split('\n')
        .slice(0, 10)
        .join('\n')
        .replace(/\n/g, '\n &nbsp;');

    return tweet?.content?.text.replace(/\n/g, '\n &nbsp;');
  }

  // check if the logged in user has like current tweet or not
  useEffect(() => {
    if (!user) return;

    const isLikedByUser = tweet?.likes.some(
      (like) => like.user === user?.user?._id
    );

    setIsLiked(isLikedByUser);
  }, []);

  return tweet?.isReshare && originalTweetLoading ? (
    <TweetSkeleton />
  ) : // check if the tweet is reshared or not and the current tweet content text is null and image and video are also not present
  tweet?.content?.text ||
    tweet?.content?.images?.length > 0 ||
    tweet?.content?.video ||
    !tweet?.isReshare ? (
    <div
      className="w-full p-4 border-b border-gray-300 bg-white  mt-4 cursor-pointer"
      onClick={() => router.push(`/feed/post/${tweet._id}`)}
    >
      <div
        className={`absolute z-[-1] opacity-0 pointer-events-none ${downloadPhotoId != tweet._id ? 'hidden' : ''}`}
      >
        <TweetCard
          tweet={tweet}
          ref={cardRef}
          originalTweet={originalTweet}
          articleData={articleData}
        />
      </div>
      <div className="flex items-start space-x-4">
        {/* Tweet Content */}
        <div className="flex-1">
          {/* Header */}
          <div className="flex items-center gap-4 justify-between">
            {/* right section */}
            <div className="flex items-center gap-3 ">
              {/* Profile Image */}
              {tweet?.author?.profileImage ? (
                <Image
                  src={tweet?.author?.profileImage}
                  alt={tweet?.author?.name || 'username'}
                  width={40}
                  height={40}
                  className="w-10 h-10 rounded-full cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    router.push(`/@${tweet?.author?.username}`);
                  }}
                />
              ) : (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    router.push(`/@${tweet?.author?.username}`);
                  }}
                  className="size-[40px] rounded-full bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center cursor-pointer"
                >
                  <span className="text-sm font-medium text-purple-600">
                    {tweet?.author?.name?.charAt(0) || 'A'}
                  </span>
                </div>
              )}

              {/* details */}
              <div className="flex gap-2 items-center">
                <span
                  className="text-[14.457px] font-medium text-[#222] cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    router.push(`/@${tweet?.author?.username}`);
                  }}
                >
                  {tweet?.author?.name || 'username'}
                </span>
                <span className="text-[#6E7881] text-[11.121px] font-normal">
                  {getTimeAgo(tweet?.createdAt)}
                </span>
              </div>
            </div>

            {/* left section */}
            <div className="flex items-center gap-2">
              {tweet?.author?._id != user?.user?._id && (
                <button
                  disabled={isFollowing}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!user) {
                      toast.error('يجب عليك تسجيل الدخول اولاً');
                      return;
                    }

                    handleFollow(e);
                  }}
                  className={`${isFollowing ? 'text-gray-500' : 'text-[#8641BD]'} text-[11.121px] font-medium `}
                >
                  {followLoading
                    ? 'جارٍ التحديث...'
                    : isFollowing
                      ? ''
                      : 'متابعة'}
                </button>
              )}

              {tweet?.author?._id == user?.user?._id && (
                <TweetActionDropDownList
                  tweet={tweet}
                  id={tweet._id}
                  isReposted={tweet?.isReshare}
                  setIsEditPostDialogOpen={setIsEditPostDialogOpen}
                />
              )}
            </div>
          </div>

          <div className="sm:mr-9 mt-2 w-[100%] sm:w-[90%] mx-auto">
            <div className="text-[#7E7A7A] text-[15px] font-medium tracking-[-0.4px] mr-3 sm:mr-4 text-pretty break-normal break-words ">
              <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkBreaks]}
                components={{
                  a: ({ node, ...props }) => (
                    <a
                      onClick={(e) => e.stopPropagation()}
                      {...props}
                      className="text-purple-600 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                }}
              >
                {renderTextContent()}
              </ReactMarkdown>
              {tweet?.content?.text.split('\n').length > 10 &&
                !showFullText && (
                  <span
                    className="text-purple-600 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowFullText(true);
                    }}
                  >
                    عرض المزيد...
                  </span>
                )}
            </div>

            {tweet?.content?.images.length == 1 && !tweet?.content?.video && (
              <Image
                src={tweet?.content?.images[0]}
                alt={tweet?.author?.name || 'username'}
                width={0}
                height={0}
                sizes="100%"
                className="w-full h-full object-cover mt-2 rounded-[10px]"
                unoptimized={true}
              />
            )}
            <div className="w-[100%]">
              {shouldCarouselOpen() && (
                <PostMediaCarousel
                  image={tweet?.content?.images}
                  video={tweet?.content?.video}
                />
              )}
            </div>
            {tweet?.content?.images.length == 0 && tweet?.content?.video && (
              <div className="relative mt-4 bg-gray-100 rounded-lg">
                <video
                  src={tweet?.content?.video}
                  controls
                  className="w-full h-full mt-2 rounded-lg"
                />
              </div>
            )}
            {!tweet?.isReshare && tweet?.content?.articleId && (
              <ArticlePreview
                article={articleData?.article}
                isLoadingArticle={isLoadingArticle}
                openSimplePreview={() =>
                  tweet?.content?.images.length != 0 || tweet?.content?.video
                }
              />
            )}
            {tweet?.isReshare && !originalTweetLoading && (
              <RepostedTweetCard originalTweet={originalTweet} />
            )}

            <div
              className="flex justify-start items-center gap-2 mt-3 text-gray-600 mr-4"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Like Button */}
              <div
                onClick={toggleLike}
                className={`flex cursor-pointer gap-1 items-center  rounded-[15px] justify-center`}
              >
                <div className="heart-bg">
                  <div className={`heart-icon ${isLiked ? 'liked' : ''}`}></div>
                  <div className="text-[#7E7A7A] text-[16px] font-medium mr-6 ml-2 transition-opacity duration-200 ease-in-out">
                    {tweet?.likes?.length > 0 ? tweet?.likes?.length : ''}
                  </div>
                </div>
              </div>

              {/* Repost Button */}
              <button className="flex items-center ">
                <RepostOptionsDropdownMenu
                  tweet={tweet}
                  repostCount={tweet?.reshares?.length}
                />
              </button>

              {/* Comment Button */}
              <div
                className={`flex gap-1 items-center hover:bg-gray-200 p-1 w-auto    min-w-[40px] h-[40px] rounded-[15px] justify-center`}
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    router.push(`/feed/post/${tweet._id}`);
                  }}
                  className={`flex items-center ${tweet?.comments?.length > 99 ? 'gap-2 ' : 'gap-1'}  rounded-[15px] justify-center`}
                >
                  <BiComment size={16} />{' '}
                  <span className="text-[#7E7A7A] text-[16px] font-medium">
                    {tweet?.comments?.length > 0 ? tweet?.comments?.length : ''}{' '}
                  </span>
                </button>
              </div>

              <ShareModal tweet={tweet} />

              {/* Download Button */}
              <button
                onClick={() => {
                  handleDownload(cardRef, setDownloadPhotoId);
                  setDownloadPhotoId(tweet._id);
                }}
                className="flex p-1 items-center gap-1 w-[30px] h-[30px] rounded-[50%] hover:bg-gray-200 justify-center"
              >
                <FiDownload size={16} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-t from-black/[0.03] to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none" />

      {/* Comment Modal */}
      {
        <CommentModal
          isOpen={isCommentModalOpen}
          tweetId={tweet._id}
          onClose={setIsCommentModalOpen}
        />
      }
      {
        <EditPostDialog
          key={tweet._id}
          tweet={tweet}
          isEditPostDialogOpen={isEditPostDialogOpen}
          setIsEditPostDialogOpen={setIsEditPostDialogOpen}
        />
      }
    </div>
  ) : (
    <>
      <SharedTweet
        creator={tweet}
        tweet={originalTweet?.post}
        user={user}
        cardRef={cardRef}
      />
    </>
  );
}
